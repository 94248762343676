<template>
  <div class="eSports-wrapper">
    <div class="title-box">
      <div class="title">支持竞赛游戏</div>
      <div class="item-box">
        <div v-for="(item, index) in gameList" :key="item.Id" :class="['item', active == index ? 'active' : '']"
          @click="changeGame(index, item)">
          <img :src="item.Img" alt="" />
        </div>
        <div class="item" v-if="gameList.length < 1">
          <img src="@/assets/images/eSports/3.png" alt="" />
        </div>
      </div>
    </div>

    <!-- <div class="text-box">
            恭喜<span>波比网吧</span>的玩家<span>永远的神</span>在<span>2021-03-12 02:12:56</span>的蚂蚁特权-绝地求生6月份城市联赛 获得<span>69</span>元奖励
        </div> -->

    <div class="tabs-box">
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <!-- <el-tab-pane label="全部" name="1"></el-tab-pane>
                <el-tab-pane label="未开始" name="2"></el-tab-pane>
                <el-tab-pane label="报名中" name="3"></el-tab-pane> -->
        <el-tab-pane label="进行中" name="2"></el-tab-pane>
        <el-tab-pane label="已结束" name="3"></el-tab-pane>
      </el-tabs>
    </div>

    <div class="file-wrap" v-show="PerfectArchives.length">
      <div class="game-file" v-for="(item, index) in PerfectArchives" :key="index">
        <div class="game-img" v-show="gameList.length">
          <img :src="gameList[active].Img" alt="" />
        </div>
        <div class="game-name">
          <p class="name">
            <!-- ellipsis {{ item.gameInfoEntity.Name }} -->
            {{ item.Title }}
          </p>
          <!-- <p class="english-name ellipsis">
                        {{ item.gameInfoEntity.EnglishName }}
                    </p> -->
          <span class="icon"><img src="@/assets/images/eSports/icon.png" alt="" />蚂蚁特权赛事运营</span>
        </div>
        <div class="file-name">
          <!-- <div v-for="(o, index) in item.PerfectArchives" :key="index" v-show="index < 3" class="ellipsis">
                        <img src="@/assets/img/down.png" alt="" /> {{ o.ArchiveName }}
                    </div> -->
          <p>
            报名时间：{{
              item.RegistrationStartTime &&
              item.RegistrationStartTime.substr(0, 10)
            }}
            —
            {{
              item.RegistrationEndTime && item.RegistrationEndTime.substr(0, 10)
            }}
          </p>
          <!-- <p>参赛人数：<span>{{ item.Enrollment }}</span>/{{ item.Count }}</p> -->
          <p>比赛时间：{{ item.ActivityStartTime }}</p>
        </div>
        <div class="reward ellipsis">
          <div v-if="item.State == 2">
            {{ item.ActivityReward && item.ActivityReward.split("@")[0] }}
          </div>
          <!-- <div v-else>
            {{ item.Remarks }}
          </div> -->
        </div>
        <div class="operation">
          <div v-if="item.State == 2">
            <el-button class="application" size="small" v-if="item.Info && item.Info.Registering && !item.Info.IsRegister"
              @click.stop.prevent="register(item)">我要报名</el-button>

            <el-tooltip class="item" effect="light" placement="bottom" v-if="item.Info && item.Info.IsRegister && !item.Info.ActivityStart
              ">
              <div slot="content" class="code-box" style="text-align: center">
                <img v-if="item.QRCode" :src="item.QRCode" width="160" height="160" alt />
                <img v-else src="@/assets/images/home/code.png" width="160" height="160" alt />
                <!-- <p>扫一扫，关注微信二维码</p> -->
                <p>添加客服微信获取比赛通知</p>
              </div>
              <el-button class="application" size="small">已报名</el-button>
            </el-tooltip>

            <el-button class="application" size="small"
              v-if="item.Info && item.Info.IsRegister && item.Info.ActivityStart"
              @click.stop.prevent="startGame(item)">开始游戏</el-button>
          </div>
          <el-button size="small" v-if="item.State == 2" @click="rules(item)">赛事规则</el-button>
          <div class="playback" v-else>
            <p v-if="item.Remarks" @click="toLiveRoom(item.Remarks)"><i class="el-icon-video-play"></i>
              回放</p>
            <p v-else>已结束</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 分页 -->
    <!--  v-if="PerfectArchives.length > 1" -->
    <div class="pagination-box" v-if="PerfectArchives.length > 0">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pageIndex" :page-size="pageSize" layout="prev, pager, next, jumper" :total="total"></el-pagination>
      <!--sizes,  :page-sizes="[8, 16, 32, 64]" -->
    </div>

    <div class="no-data" v-if="PerfectArchives.length == 0">
      <img src="@/assets/img/noData.png" alt="" />
      <p>暂无赛事</p>
    </div>

    <!-- 报名成功弹窗 -->
    <el-dialog title="报名成功" :visible.sync="centerDialogVisible" width="30%" center :close-on-click-modal="false">
      <div style="text-align: center">
        <img :src="this.codeImg" width="100%" style="margin: 10px 0" alt="" />
        <p style="color: red; font-size: 16px">
          赛事规则、奖励领取，扫码添加平台客服
        </p>
      </div>
    </el-dialog>

    <!-- 赛事规则弹窗 -->
    <!-- <el-dialog title="赛事规则" custom-class="game-dialog" :visible.sync="outerVisible" width="100%" center
            :close-on-click-modal="false">
            <div class="game-box">
                <div class="grid item1">
                    <div class="title">
                        蚂蚁特权杯-绝地求生6月份城市联赛
                    </div>
                    <div class="text">
                        本次蚂蚁特权杯赛涉及20余个省市自治区，300+网吧门店，最终将有16名单排选手和16只三排 队伍杀出重围，夺取城市联赛总冠军的荣耀。各位天选之人可通过网易BATTLE选取向您最近
                        的网吧进行报名，前往线下参赛，每家网吧门店冠军可获得丰厚赛事奖励。
                    </div>
                </div>
                <div class="grid item2">2</div>
                <div class="grid item3">3</div>
            </div>
        </el-dialog> -->

    <el-drawer title="" custom-class="game-dialog" :visible.sync="drawer" :direction="direction" :withHeader="false">
      <div class="game-box" v-if="rowObj">
        <div class="close" @click="drawerClose">
          <i class="el-icon-circle-close"></i>
        </div>
        <!-- <img class="code-img" src="@/assets/images/eSports/code.png" alt="" /> -->
        <div class="grid item1">
          <div class="title">
            {{ rowObj.Title }}
          </div>
          <!-- <div class="text">
                        {{ rowObj.ActivityRules }}
                    </div> -->
        </div>
        <div class="grid item2">
          <div class="text" v-if="rowObj.ActivityReward">
            <div class="left">{{ rowObj.ActivityReward.split("@")[0] }}</div>
            <div class="right">{{ rowObj.ActivityReward.split("@")[1] }}</div>
          </div>
        </div>
        <div class="grid item3" v-if="rowObj.ActivityRules">
          <div class="text">
            <p v-for="(item, index) in rowObj.ActivityRules.split('@')" :key="index">
              {{ item }}
            </p>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  GetActivity2,
  GetActivityGames,
  SaveActivity2Participant,
  GetNavigationList,
  GetActivity2Participants,
  CompleteTask
} from "@/api";
import { mapGetters } from "vuex";
import cMethods from "@/util/cMethods.js";
import { myMixins } from "@/util/mixins.js";

export default {
  mixins: [myMixins],
  computed: {
    ...mapGetters(["isLogin", "userInfo", "netBarId", "netBarName"]),
  },
  data() {
    return {
      gridData: [],
      PerfectArchives: [],
      gameName: "",
      pageSize: 4,
      pageIndex: 1,
      total: 0,
      gameObj: null,
      keyword: "",
      gameId: null,
      activeName: "2",
      centerDialogVisible: false,
      codeImg: "",
      outerVisible: false,
      drawer: false,
      direction: "ttb",
      usersData: [],
      rowObj: null,
      gameList: [],
      active: 0,
    };
  },
  mounted() {
    // 获取赛事游戏
    this._GetActivityGames();
    _czc.push(["_trackEvent", "赛事活动首页", "页面", "展现量"]);
  },
  methods: {
    startGame(item) {
      // 开始游戏
      this._CompleteTask2(1018, () => {
        this.$router.push(`/gameDetail/${item.ProductId}`)
      })
    },
    async _CompleteTask2(typeId, cb) {
      if (this.userInfo && this.userInfo.ConsumerId && this.netBarId) {
        let params = {
          UserId: this.userInfo.ConsumerId,
          NetbarId: parseInt(this.netBarId),
          Type: parseInt(typeId)
        }
        let res = await CompleteTask(params)
        cb && cb()
      }
    },
    // 回放
    toLiveRoom(url) {
      _czc.push(['_trackEvent', '直播', '回放', '点击人数']);
      this.$router.push({
        path: 'LiveRoom',
        query: {
          url: encodeURIComponent(url)
        }
      })
    },
    // 赛事规则
    async rules(row) {
      this.rowObj = row;
      this.drawer = true;
      this.outerVisible = true;
      // console.log(row.Id);
      let params = {
        activityId: row.Id,
        userId: 0,
        pageSize: 12,
        pageIndex: 1,
      };
      let res = await GetActivity2Participants(params);
      if (res.StatusCode) {
        this.usersData = res.Items;
      }

      _czc.push(["_trackEvent", this.rowObj.Title, "赛事规则", "按钮点击量"]);
    },
    // 切换游戏
    changeGame(index, item) {
      this.active = index;
      this.gameName = item.GameName;
      this.pageIndex = 1;
      this._GetGameList();
    },
    // 切换
    handleTabClick(e) {
      this.activeName = e.name;
      this.pageIndex = 1;
      this._GetGameList();
    },
    // 获取赛事活动报名成功二维码
    async _GetNavigationList(type) {
      let params = {
        type: type,
      };
      let res = await GetNavigationList(params);
      if (res.StatusCode) {
        let imgOjb = res.Object;
        this.codeImg = imgOjb[0].ImgUrl;
        this.centerDialogVisible = true;
      }
    },
    // 下载
    // downLoad(row) {
    //     if (this.isLogin && this.userInfo) {
    //         if (this.gameObj) {
    //             this.gameId = this.gameObj.GameID;
    //             if (
    //                 typeof this.gameId == "string" &&
    //                 this.gameId.indexOf("GM") > -1
    //             ) {
    //                 this.gameId = parseInt(this.gameId.replace("GM", ""));
    //             }
    //             this.$confirm("请先关闭游戏和备份当前电脑游戏存档再进行下载！", {
    //                 confirmButtonText: "下载并替换存档",
    //                 distinguishCancelAndClose: true,
    //             })
    //                 .then(() => {
    //                     cMethods.Form_jsDownSaveForTQ(this.gameId, row.ArchiveUrl);
    //                 })
    //                 .catch((action) => { });
    //         }
    //     } else {
    //         eventBus.$emit("getQrCode");
    //     }
    // },
    // 获取赛事活动游戏图片

    async _GetActivityGames() {
      let res = await GetActivityGames();
      if (res.StatusCode == 200) {
        this.gameList = res.Object;
        this.gameName = res.Object[0].GameName;
        // 获取赛事列表
        this._GetGameList();
      }
    },
    // 获取游戏列表
    async _GetGameList() {
      if (this.netBarId) {
        let params = {
          netbarId: this.netBarId,
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
          state: this.activeName,
          userId: (this.userInfo && this.userInfo.ConsumerId) || 0,
          gameName: this.gameName,
        };

        let res = await GetActivity2(params);

        if (res.StatusCode == 200) {
          this.PerfectArchives = res.Items;
          this.total = res.Total;

          if (this.$route.query.gameId) {
            this.gameId = this.$route.query.gameId;
          }
        }
      } else {
        this.$message({
          message: "获取网吧ID失败！",
          type: "error",
        });
      }
    },
    // 一页展示多少条
    handleSizeChange(val) {
      this.pageIndex = 1;
      this.pageSize = val;
      this._GetGameList();
    },
    // 分页
    handleCurrentChange(val) {
      this.pageIndex = val;
      this._GetGameList();
    },
    async register(item) {
      this.gameObj = item;
      if (this.isLogin && this.userInfo) {
        if (!this.userInfo.Phone) {
          eventBus.$emit("showLoginPhone");
          return false;
        }

        if (!this.userInfo.IDCard) {
          eventBus.$emit("showAuth");
          return false;
        }

        this.$confirm(
          `是否确认报名参加本场比赛？<br/>赛事名称：${this.gameObj.Title}<br/>开始时间：${this.gameObj.ActivityStartTime}`,
          //   `是否确认参与${this.gameObj.Title}的赛事报名？`,
          "提示：",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            dangerouslyUseHTMLString: true,
          }
        )
          .then(async () => {
            let params = {
              ActivityId: item.Id,
              UserId: this.userInfo.ConsumerId,
              Phone: this.userInfo.Phone,
              NetbarId: this.netBarId,
              NetbarName: this.netBarName,
            };
            let res = await SaveActivity2Participant(params);
            if (res.StatusCode == 200) {
              if (item.QRCode) {
                this.codeImg = item.QRCode;
                this.centerDialogVisible = true;
              } else {
                this._GetNavigationList(6);
              }
              // this.pageIndex = 1;
              this._GetGameList();
            }
          })
          .catch(() => { });
      } else {
        eventBus.$emit("getQrCode");
      }
      _czc.push(["_trackEvent", this.gameObj.Title, "我要报名", "按钮点击量"]);
    },
    handleSearch() {
      this.pageIndex = 1;
      this._GetGameList();
    },
    handleClick() {
      this.$router.push(`/gameDetail/${this.gameObj.ProductID}`);
    },
    drawerClose() {
      this.drawer = false;
      _czc.push([
        "_trackEvent",
        this.rowObj.Title,
        "赛事规则",
        "关闭按钮点击量",
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.eSports-wrapper {
  padding: 20px;

  .title-box {
    padding: 16px;
    @include item_bg_col();
    border-radius: 4px;

    .title {
      font-size: 20px;
      font-weight: 500;
      @include font_col3();
      position: relative;
      padding-left: 8px;

      &::before {
        width: 3px;
        height: 20px;
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        background: $red_col;
      }
    }

    .item-box {
      display: flex;

      margin-top: 8px;

      .item {
        margin-right: 10px;
        width: 141px;
        height: 66px;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }

        &.active {
          border: 2px solid #ff3636;
        }
      }
    }
  }

  .text-box {
    margin-top: 16px;
    font-size: 15px;
    @include font_col3();

    span {
      color: $red_col;
    }
  }

  .tabs-box {
    // @include item_bg_col();
    // padding: 0 20px;
    margin-top: 5px;
  }

  .file-wrap {
    .game-file {
      margin-top: 5px;
      padding: 10px 20px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      @include item_bg_col();
      line-height: 24px;

      .game-img {
        margin-right: 10px;

        img {
          width: 190px;
          height: 89px;
          display: block;
          border-radius: 4px;
        }
      }

      .game-name {

        // width: 300px;
        .name {
          width: 220px;
          padding-right: 20px;
          @include font_col3();
          font-size: 20px;
        }

        .english-name {
          width: 220px;
          @include font_col9();
          font-size: 16px;
        }

        .icon {
          margin-top: 10px;
          // padding: 0px 8px;
          display: inline-block;
          font-size: 12px;
          // color: #fff;
          color: $red_col;
          // background: linear-gradient(90deg, #ff3636 0%, #ff765a 100%);
          border-radius: 2px;
          display: flex;
          align-items: center;

          img {
            margin-right: 4px;
          }
        }
      }

      .file-name {
        width: 300px;
        @include font_col6();

        img {
          margin-right: 5px;
        }

        span {
          color: $red_col;
        }
      }

      .reward {
        width: 140px;
        color: $red_col;
      }

      // .file-size {
      //   width: 120px;
      //   @include font_col6();
      // }
      .operation {
        width: 120px;
        text-align: right;

        .playback {
          p {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }

          ::v-deep .el-icon-video-play {
            font-size: 20px;
            margin-right: 5px;
            color: #ff3636;
          }
        }

        .el-button {
          width: 118px;
          margin: 3px 0;
          border: none;
          // background: linear-gradient(0deg, #ff4f4f 0%, #ff7878 100%);
          // color: #fff;
          color: $red_col;
          border: 1px solid $red_col;
          background: transparent;

          &:hover {
            color: #fff;
            background: $red_col;
          }

          &.application {
            background: $red_col;
            color: #fff;
          }

          &.application:hover {
            color: $red_col;
            background: transparent;
          }
        }
      }

      &:hover {
        transform: translateY(-5px);
        transition: 0.2s ease;
        box-shadow: -2px 0px 10px 0px rgb(0 0 0 / 20%);
      }
    }
  }

  .pagination-box {
    margin-top: 10px;
    text-align: center;
  }

  .no-data {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin-top: 10px;
      font-size: 16px;
      @include font_col3();
    }
  }
}
</style>

<style lang="scss">
.file-dialog {
  .el-dialog__body {
    padding: 10px !important;

    .el-table .el-table__cell {
      padding: 5px 0 !important;
    }

    .img-box {
      position: relative;

      .icon {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 99;
        font-size: 12px;
        color: #fff;
      }
    }

    .el-button {
      border: none;
      // background: linear-gradient(0deg, #ff4f4f 0%, #ff7878 100%);
      // color: #fff;
      color: $red_col;
      border: 1px solid $red_col;
      background: transparent;

      &:hover {
        color: #fff;
        background: $red_col;
      }
    }
  }
}

.game-dialog {
  height: 100vh !important;

  .el-drawer__body::-webkit-scrollbar {
    display: none;
  }

  .el-drawer__body {
    height: 100vh;
    padding: 0 !important;

    // .game-box::-webkit-scrollbar {
    //     display: none;
    // }

    .game-box {
      height: 100vh;
      position: relative;

      // overflow-y: scroll;
      .close {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 99;
        color: #fff;
        font-size: 40px;
        cursor: pointer;
      }

      .code-img {
        width: 160px;
        height: 160px;
        position: absolute;
        top: 290px;
        right: 120px;
        z-index: 99;
      }

      .grid {
        color: #ead175;
      }

      .item1 {
        height: 624px;
        background: url("../assets/images/eSports/bg_01.jpg") no-repeat;
        background-size: 100% 100%;
        position: relative;

        .title {
          position: absolute;
          width: 100%;
          text-align: center;
          bottom: 46px;
          line-height: 80px;
          font-size: 52px;
          font-weight: bold;
          background: linear-gradient(0deg, #fcdf8e 0%, #fefffd 90.8447265625%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .text {
          padding: 20px 120px;
          width: 100%;
          text-align: center;
          position: absolute;
          bottom: 0;
          font-size: 16px;
          box-sizing: border-box;
          line-height: 32px;
          text-align: left;
          text-indent: 2em;
        }
      }

      .item2 {
        height: 599px;
        background: url("../assets/images/eSports/bg_02.jpg") no-repeat;
        background-size: 100% 100%;
        position: relative;
        text-align: center;

        .text {
          width: 1122px;
          height: 88px;
          margin: 0 auto;
          position: absolute;
          bottom: 40px;
          left: 50%;
          margin-left: -561px;
          display: flex;
          font-size: 20px;
          color: #b9ae9d;
          padding: 0 70px;
          box-sizing: border-box;

          .left {
            width: 50%;
          }

          .right {
            width: 50%;
          }
        }
      }

      .item3 {
        height: 913px;
        background: url("../assets/images/eSports/bg_03.jpg") no-repeat;
        background-size: 100% 100%;
        position: relative;

        .text {
          width: 1122px;
          margin: 0 auto;
          position: absolute;
          top: 160px;
          left: 50%;
          margin-left: -561px;
          // display: flex;
          font-size: 16px;
          color: #b9ae9d;
          padding: 0 70px;
          box-sizing: border-box;
          // display: flex;
          // justify-content: space-around;
          // flex-wrap: wrap;
          line-height: 28px;
          text-indent: 2em;

          // .item {
          //     width: 151px;
          //     height: 175px;
          //     margin: 10px 5px;
          //     display: block;
          //     background: url('../assets/images/eSports/item_bg.png') no-repeat;
          //     background-size: 100% 100%;
          //     position: relative;

          //     .phone {
          //         width: 100%;
          //         position: absolute;
          //         left: 0;
          //         bottom: 20px;
          //         text-align: center;
          //     }
          // }
        }
      }
    }
  }
}
</style>
